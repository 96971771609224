import { Storage, Dispatch } from 'components/Provider/Provider';
import { useContext } from 'react';
import { LocalStorage } from 'types';

export const useLocalStorage = () => {
  const storage = useContext(Storage);
  const dispatch = useContext(Dispatch);

  const appState: [
    LocalStorage,
    (updates: Record<string, string | object | null>) => void
  ] = [storage, dispatch];

  return appState;
};
