import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route as PublicRoute,
} from 'react-router-dom';
import i18next from 'i18next';
import { resources } from './i18n/i18n';
import { toast } from 'react-toastify';
import ScrollToTop from 'consts/ScrollToTop';

import { Provider } from 'components/Provider/Provider';
import { Suspense } from 'react';
import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute';

function App() {
  i18next.init({
    lng: localStorage.we_lang, //language,
    fallbackLng: 'en',
    debug: false,
    resources: resources,
    interpolation: {
      escapeValue: false,
    },
    keySeparator: '.',
  });
  toast.configure();

  return (
    <Router>
      <ScrollToTop />
      <Suspense fallback={<div></div>}>
        <Provider>
          <Switch>
            {/* PUBLIC ROUTES */}
            <PublicRoute
              exact
              path='/'
              component={React.lazy(() => import('pages/auth/newLogin'))}
            />
            <PublicRoute
              exact
              path='/login'
              component={React.lazy(() => import('pages/auth/newLogin'))}
            />
            <PublicRoute
              exact
              path='/register'
              component={React.lazy(() => import('pages/auth/Register'))}
            />
            <PublicRoute
              exact
              path='/user-management'
              component={React.lazy(() => import('pages/UserManagment'))}
            />
            <PublicRoute
              exact
              path='/new-password-confirmation'
              component={React.lazy(
                () => import('pages/myAccount/NewPasswordConfirmation')
              )}
            />
            <PublicRoute
              exact
              path='/forgot-password-link-sent'
              component={React.lazy(
                () => import('pages/auth/ForgotPasswordLinkSent')
              )}
            />
            <PublicRoute
              exact
              path='/form-sent-confirmation'
              component={React.lazy(
                () => import('pages/auth/FormSentConfirmation')
              )}
            />
            <PublicRoute
              exact
              path='/forgot-password'
              component={React.lazy(() => import('pages/auth/ForgotPassword'))}
            />
            <PublicRoute
              exact
              path='/choose-new-password'
              component={React.lazy(
                () => import('pages/myAccount/ChooseNewPassword')
              )}
            />
            <PublicRoute
              exact
              path='/initial-password-setup'
              component={React.lazy(
                () => import('pages/auth/SetupInitialPassword')
              )}
            />
            <PublicRoute
              exact
              path='/contact'
              component={React.lazy(() => import('pages/Contact'))}
            />
            <PublicRoute
              exact
              path='/cookie-policy'
              component={React.lazy(() => import('pages/CookiePolicy'))}
            />
            <PublicRoute
              exact
              path='/terms-and-conditions'
              component={React.lazy(() => import('pages/TermsAndConditions'))}
            />
            <PublicRoute
              exact
              path='/privacy-policy'
              component={React.lazy(() => import('pages/PrivacyPolicy'))}
            />
            <PublicRoute
              exact
              path='/disclaimer'
              component={React.lazy(() => import('pages/Disclaimer'))}
            />

            {/* PROTECTED ROUTES */}
            <ProtectedRoute
              path='/change-password'
              component={React.lazy(
                () => import('pages/myAccount/ChangePassword')
              )}
            />
            <ProtectedRoute
              exact
              path='/my-account'
              component={React.lazy(() => import('pages/myAccount/Account'))}
            />
            <ProtectedRoute
              exact
              path='/dashboard'
              component={React.lazy(() => import('pages/Dashboard'))}
            />
            <ProtectedRoute
              exact
              path='/dashboard/blends'
              component={React.lazy(() => import('pages/Blends'))}
            />
            <ProtectedRoute
              exact
              path='/dashboard/blends/:slugtitle'
              component={React.lazy(() => import('pages/Blends'))}
            />
            <ProtectedRoute
              exact
              path='/products'
              component={React.lazy(() => import('pages/products/Products'))}
            />
            <ProtectedRoute
              path='/product/:language/:slugtitle'
              component={React.lazy(() => import('pages/products/Product'))}
            />
            <ProtectedRoute
              exact
              path='/protocols'
              component={React.lazy(() => import('pages/protocols/Protocols'))}
            />
            <ProtectedRoute
              exact
              path='/protocol/:language/:slugtitle'
              component={React.lazy(() => import('pages/protocols/Protocol'))}
            />

            <ProtectedRoute
              exact
              path='/events'
              component={React.lazy(() => import('pages/events/Events'))}
            />
            <ProtectedRoute
              path='/event/:id/:activeDate/:language/:slugname'
              component={React.lazy(() => import('pages/events/Event'))}
            />
            <ProtectedRoute
              exact
              path='/consultations'
              component={React.lazy(() => import('pages/Consultation'))}
            />
            {/* <ProtectedRoute exact path='/resources' component={ResourcesPage} /> */}
            <ProtectedRoute
              exact
              path='/new-article'
              component={React.lazy(() => import('pages/eduCare/NewArticle'))}
            />

            <ProtectedRoute
              exact
              path='/edit-article/:language/:slugname'
              component={React.lazy(() => import('pages/eduCare/EditArticle'))}
            />

            <ProtectedRoute
              exact
              path='/eduCare'
              component={React.lazy(() => import('pages/eduCare/EduCare'))}
            />
            <ProtectedRoute
              exact
              path='/eduCare/:language/:slugname'
              component={React.lazy(() => import('pages/eduCare/Article'))}
            />

            {/* NOT FOUND */}
            <PublicRoute
              component={React.lazy(() => import('pages/Page404'))}
            />
          </Switch>
        </Provider>
      </Suspense>
    </Router>
  );
}

export default App;
