export const stringsSE = {
  schema: {
    firstName: {
      invalid: 'Vă rugăm să introduceți un nume valid. SRB',
      required: 'Prenumele este obligatoriu. SRB',
      min: 'Prenumele trebuie să aibă cel puțin 2 caractere. SRB',
      max: 'Prenumele nu poate depăși 55 de caractere. SRB',
    },
    lastName: {
      invalid: 'Vă rugăm să introduceți un nume valid. SRB',
      required: 'Numele este obligatoriu. SRB',
      min: 'Numele trebuie să aibă cel puțin 2 caractere. SRB',
      max: 'Numele nu poate depăși 55 de caractere. SRB',
    },
    email: {
      required: 'Adresa de email este obligatorie. SRB',
      invalid: 'Adresa email invalidă. SRB',
    },
    country: {
      required: 'Țara este obligatorie. SRB',
    },
    language: {
      required: 'Limba este obligatorie. SRB',
    },
    title: {
      required: 'Titlul este obligatoriu. SRB',
    },
    eventImage: {
      required: 'Imaginea evenimentului este obligatorie. SRB',
    },
    zoomLink: {
      invalid: 'Link invalid. SRB',
      required: 'Este necesar un link de Zoom. SRB',
    },
    phone: {
      required: 'Este necesar un număr de telefon. SRB',
      invalid: 'Număr de telefon invalid. SRB',
    },
    doterraId: {
      required: 'doTERRA ID este obligatoriu. SRB',
      invalid: 'doTERRA ID invalid. SRB',
    },
    password: {
      required: 'Parola este obligatorie. SRB',
      short: 'Parolă trebuie să conțină de cel puțin 8 caractere. SRB',
      invalid: 'Cerințele privind parola nu sunt îndeplinite. SRB',
    },
    generic: 'Acest camp este obligatoriu. SRB',
  },
  // generics
  generic: {
    cancel: 'Anulare SRB',
    forms: {
      firstName: 'Prenume SRB',
      lastName: 'Nume SRB',
      language: 'Limba SRB',
      phone: 'Număr de telefon SRB',
      doterraId: 'doTERRA ID SRB',
      email: 'Adresa de email SRB',
      password: 'Parola SRB',
    },
  },
  // avatar dropdown header
  hello: 'Salut SRB',
  homepage: 'Accesați homepage SRB',
  myAccount: 'Contul meu SRB',
  userManagement: 'Gestionarea utilizatorilor SRB',
  contact: 'Contact SRB',
  logout: 'Deconectați-vă SRB',
  help: 'Ajutor SRB',
  // navigation
  products: 'Produse SRB',
  protocols: 'Protocoale SRB',
  resources: 'Resurse SRB',
  bookings: 'Programări SRB',
  events: 'Evenimente SRB',
  search: 'Cautare SRB',
  about: 'Despre SRB',
  whyJoin: 'Beneficii SRB',
  faq: 'FAQ SRB',
  liveEvent: 'Eveniment Live SRB',
  login: 'Autentificare SRB',
  changeLanguage: 'Schimbați limba SRB',
  // login page
  loginTitle: 'Autentificare membru SRB',
  loginSubtitle: 'O lume plină de magie și uleiuri esențiale doTERRA SRB',
  typeEmail: 'Introduceți adresa de email SRB',
  typePassword: 'Introduceți parola SRB',
  forgotPassword: 'Ați uitat parola? SRB',
  registerLink: 'Nu ai cont? SB', //new
  buttonLogin: 'Autentificare SRB',
  // my account page
  myAccountPage: {
    removeAvatar: 'Eliminați imaginea SRB',
    country: 'Țara de reședință SRB',
    language: 'Selectează limba SRB',
    doterraId: 'ID-ul doTERRA al utilizatorului SRB',
    emailVerified: 'Email verificat SRB',
    changePassword: 'Schimbați parola SRB',
    update: 'Actualizați informațiile SRB',
    deletedAccount: 'Contul dumneavoastră a fost șters cu succes. SRB',
    deleteModal: {
      topText:
        'Sunteți pe cale să vă ștergeți complet contul și toate informațiile acestuia. SRB',
      bottomText: {
        firstPart: 'Sunteți sigur că doriți SRB',
        redText: 'ștergea permanentă SRB',
        secondPart: 'a contului dumneavoastră? SRB',
      },
    },
  },
  //userManagement page
  usersManagement: {
    addUser: '+ Adaugă Utilizator SRB',
    all: 'Toate SRB',
    serverError: 'Server error SRB',
    genericError: 'Something went wrong. Try refreshing the page. SRB',
  },

  userManagementModal: {
    addUser: 'Poți adaugă doar utilizatori SRB',
    website: 'Înrolați în Wonderland Essentials. SRB',
    enrolledStatus: 'Înrolat SRB',
    content: 'Are un cont doTERRA sub Wonderland Essentials. SRB',
    doterraId: 'doTERRA ID SRB',
    enroller: 'Înrolator SRB',
    account: 'Tipul contului SRB',
    firstName: 'Prenume SRB',
    lastName: 'Nume SRB',
    emailAddress: 'Adresa de email SRB',
    country: 'Țara SRB',
    phone: 'Număr de telefon SRB',
    createAccount: 'Creați un cont nou de utilizator SRB',
    editAccount: 'Editare cont de utilizator SRB',
    deleteAccount: 'Ștergere cont de utilizator SRB',
    addedUser: 'Contul de utilizator a fost creat cu succes:  SRB',
    updatedUser: 'Detalii de utilizator modificate cu succes pentru  SRB',
    removedUser: 'Utilizator șters:  SRB',
    confirmedUser: 'Utilizator confirmat cu succes SRB',
    addButton: 'Creați un utilizator nou SRB',
    updateButton: 'Actualizați informațiile SRB',
    cancelButton: 'Anulare SRB',
    statusLabel: 'Starea contului SRB',
    accountType: {
      info: 'Ce sunt tipurile de cont? SRB',
      admin: {
        type: 'Administrator SRB',
        content:
          ' vede toate protocoalele, evenimentele și are acces la baza de date a utilizatorilor. SRB',
      },
      consumer: {
        type: 'Consumator SRB',
        content: ' vede protocoalele și evenimentele selectate. SRB',
      },
    },
    accountStatus: {
      title: 'Ce este starea contului? SRB',
      notContacted: {
        tag: 'Necontactat SRB',
        content: 'Fără cont doTERRA și așteaptă să fie contactat. SRB',
      },
      pending: {
        tag: 'În așteptare SRB',
        content: 'Contactat și nu are încă un cont doTERRA. SRB',
      },
      enrolled: {
        tag: 'Înrolat SRB',
        content: 'Are un cont doTERRA sub Wonderland Essentials. SRB',
      },
    },
    phoneNumber: 'Număr de telefon SRB',
    deletedAccount: 'Contul dumneavoastră a fost șters cu succes. SRB',
    deleteModal: {
      confirm: 'Confirmă ștergerea SRB',
      topText:
        'Sunteți pe cale să ștergeți complet acest utilizator și toate informațiile sale. Această acțiune nu poate fi revocată. SRB',
      bottomText: {
        firstPart: 'Sunteți sigur că vreți să  SRB',
        redText: 'pștergeți complet SRB',
        secondPart: ' contul acestui utilizator? SRB',
      },
      deleteButton: 'Ștergere SRB',
      cancelButton: 'Anulare SRB',
    },
    errors: {
      createUser: 'Contul de utilizator nu a putut fi creat SRB',
      confirmUser: 'Contul de utilizator nu a putut fi confirmat SRB',
      email: 'Această adresă de email este deja folosită. SRB',
      updateUser:
        'A apărut o eroare la actualizarea detaliilor utilizatorului. SRB',
      doterraId: 'ID-ul doTERRA este utilizat de un alt cont. SRB',
    },
  },
  // initial password setup page
  initialPasswordPage: {
    title: 'Bine ați venit pe Wonderland Essentials SRB',
    subtitle:
      'Suntem foarte încântați că ați decis să vă alăturați echipei Wonderland Essentials. Sperăm ca în curând sa aflați și ce decizie excelentă ați luat! SRB',
    text: 'Dar mai întâi, va invităm să va creți propria parolă! SRB',
    yourPassword: 'Parolă dumneavoastră SRB',
    confirmPassword: 'Confirmare parolă SRB',
    update: 'ACTUALIZAȚI PAROLA SRB',
  },
  // change password page
  chooseNewPasswordPage: {
    title: 'Alegeți o parolă nouă SRB',
    passwordRequirements:
      'Parolele trebuie să aibă cel puțin 8 caractere și trebuie să conțină cel puțin 1 cifră, 1 literă mare, 1 literă mică și 1 caracter special. SRB',
    oldPassword: 'Parolă veche SRB',
    newPassword: 'Parolă nouă SRB',
    confirmNewPassword: 'Confirmare parolă nouă SRB',
    save: 'SALVARE PAROLĂ SRB',
    confirm: {
      title: 'Parola a fost schimbată cu succes SRB',
      loginBTN: 'AUTENTIFICARE SRB',
    },
  },
  loginPage: {
    emailLabel: 'Email address SRB',
    emailInput: 'Your email address SRB',
    passwordLabel: 'Password SRB',
    passwordInput: 'Your password SRB',
    forgotPassword: 'Forgot password? SRB',
    label: 'Become a member SRB',
  },
  // set register page
  registerPage: {
    title: 'Lasă-ne datele tale și te vom contacta SRB',
    subtitle: 'De ce nu pot să-mi creez, pur și simpu, un cont?  SRB',
    text: 'Pentru că noi credem în corectitudine. Credem că pentru a primi respect, trebuie mai întâi să-l oferim. Prin urmare, pentru a se potrivi cu modelul de afaceri doTERRA și cu alți avocați doTERRA, Wonderland Essentials acceptă doar membrii care nu au un cont doTERRA.  SRB',
    information: 'Cum funcționează?  SRB',
    textInformation:
      'Cineva te va contacta telefonic în termen de 24 de ore și te va ajuta cu răspunsuri și cu configurarea inițială. SRB',
    firstName: 'Nume SRB',
    lastName: 'Prenume SRB',
    yourEmail: 'Adresă de email SRB',
    countryOfResidence: 'Țară de reședința SRB',
    phoneNumber: 'Număr de telefon SRB',
    doTerraAccount: 'Nu am un cont doTERRA SRB',
    termsAndConditions: 'Accept termenii și condițiile SRB',
    registration: 'Aplicați pentru înregistrare SRB',
    contact:
      '*Pentru orice alte întrebări va rugăm să ne scrieți la adresa  SRB',
    website: 'support@wonderlandessentials.com SRB',
  },
  //
  // set register page
  formSentConfirmationPage: {
    title: 'Cererea dumneavoastră a fost trimisă SRB',
    subtitle:
      'Îți mulțumim pentru interesul tău de a face parte din echipa Wonderland Essentials. SRB',
    text: 'Vom încerca să vă contactăm telefonic în 24 de ore. În caz contrar, vă rugăm să trimiteți un e-mail la  SRB',
    website: 'office@wonderlandessentials.com SRB',
    btnText: 'INAPOI LA WEBSITE SRB',
  },
  // change password page
  changePassword: 'Schimbă parolă SRB',
  passwordRequirements:
    'Parola trebuie să aibă cel puțin 8 caractere și trebuie să conțină cel puțin 1 cifră, 1 literă mare, 1 literă mica și 1 caracter special. SRB',
  oldPassword: 'Parolă veche SRB',
  newPassword: 'Parolă nouă SRB',
  confirmNewPassword: 'Confirmă parolă nouă SRB',
  confirmPasswordChange: 'SCHIMBĂ PAROLĂ SRB',
  // form validation messages
  passwordsNoMatch: 'Parolele nu se potrivesc! SRB',
  // error messages
  profileUpdateFail: `A apărut o problemă la actualizarea detaliilor contului. Încercați mai târziu.`,
  // success messages
  profileUpdated: `Profil actualizat cu succes.`,
  // forgot password page
  forgotPasswordPage: {
    text: 'Introduceți adresa de e-mail asociată contului dvs. și vom trimite un link pentru a vă reseta parola. SRB',
    email: 'Adresa email SRB',
    buttons: {
      continue: 'Continuare SRB',
      cancel: 'Anulare SRB',
    },
    linkSent:
      'Am trimis un e-mail la adresa de e-mail furnizată cu instrucțiuni suplimentare. SRB',
    backToHomepage: 'Înapoi la Website SRB',
  },

  // products
  productsPage: {
    pageTitle: 'Produse SRB',
    addProduct: '+ Adaugă produs SRB',
    showing: {
      pre: 'Se afișează SRB',
      post: 'Produse SRB',
      plural: 's SRB',
      none: 'Nici un produs SRB',
    },
    productAdded: 'Produse adăugate cu succes. SRB',
    productNotSaved: 'Produsul nu a putut fi salvat. SRB',
    addProductTitle: 'Creați un produs nou SRB',
    addProductButton: 'Creați produs SRB',
    selectProductLanguage: 'Selectați limba produsului SRB',
    applicationMethod: 'Metoda de aplicare SRB',
    selectLanguage: 'Selectați limba produsului SRB',
    a: 'A - Aromatic SRB',
    t: 'T - Topic SRB',
    i: 'I - Intern SRB',
    skinSensitivity: 'Sensibilitatea pielii SRB',
    n: 'N - Nediluat SRB',
    s: 'S - Sensibilă SRB',
    d: 'D - Diluați SRB',
    title: 'Titlul produsului SRB',
    altName: 'Nume alternativ SRB',
    category: 'Categoria produsului SRB',
    extractionMethod: 'Metoda de extracție SRB',
    description: 'Descriere SRB',
    ingredients: 'Ingrediente SRB',
    benefits: 'Beneficii SRB',
    cautions: 'Precauții SRB',
    translatedBy: 'Mulțumiri SRB',
    imageRequired: 'Vă rugăm să selectați o imagine de produs. SRB',
  },
  // single product page
  productPage: {
    failedToFetch: 'Nu s-au putut prelua datele despre produse. SRB',
    breadcrumbs: {
      all: 'Toate produsele SRB',
    },
    selectProductLanguage: 'Selectați limba produsului SRB',
    faledToFetchSimilar: 'Nu s-au putut obține produse similare. SRB',
    deletedProduct: 'Produsul a fost șters. SRB',
    imageRequired: 'Vă rugăm să selectați o imagine de produs. SRB',
    updated: 'Produsul a fost actualizat cu succes. SRB',
    failedUpdate: 'Nu s-au putut actualiza detaliile produsului. SRB',
    failedDelete: 'Produsul nu a putut fi șters. SRB',
    editProduct: 'Editați produsul SRB',
    addProductTitle: 'Creați un produs nou SRB',
    updateProduct: 'Actualizați produsul SRB',
    deleteProduct: 'Ștergeți produsul SRB',
    initials: {
      a: 'A SRB',
      t: 'T SRB',
      i: 'I SRB',
      n: 'N SRB',
      s: 'S SRB',
      d: 'D SRB',
    },
    title: 'Titlul produsului SRB',
    altName: 'Nume alternativ SRB',
    category: 'Categorie produs SRB',
    extractionMethod: 'Metoda de extracție SRB',
    description: 'Descriere SRB',
    ingredients: 'Ingrediente SRB',
    benefits: 'Beneficii SRB',
    cautions: 'Precauții SRB',
    similarTitle: 'Descoperiți produse similare SRB',
    noSimilarProducts: 'Nu există produse similare SRB',
    extraction: {
      none: 'Niciuna',
      notApplicable: 'Nu se aplica',
      steamDistillationOfFlowers: 'Steam distillation of flowers',
      steamDistillationOfLeaves: 'Steam distillation of leaves',
      steamDistillationOfFruit: 'Steam distillation of fruit',
      steamDistillationOfRoots: 'Steam distillation of roots',
      steamDistillationOfRhizomes: 'Steam distillation of rhizomes',
      steamDistillationOfWood: 'Steam distillation of wood',
      steamDistillationOfResin: 'Steam distillation of resin',
      steamDistillationOfSap: 'Steam distillation of sap',
      steamDistillationOfSeeds: 'Steam distillation of seeds',
      steamDistillationOfBark: 'Steam distillation of bark',
      steamDistillationOfLeavesAndFlowers:
        'Steam distillation of leaves and flowers',
      steamDistillationOfNeedlesAndTwigs:
        'Steam distillation of needles and twigs',
      steamDistillationOfFruitsLeavesAndSeeds:
        'Steam distillation of fruits, leaves and seeds',
      steamDistillationOfLeavesAndYoungBranches:
        'Steam distillation of leaves and young branches',
      steamDistillationOfLeavesAndPlantTips:
        'Steam distillation of leaves and plant tips',
      steamDistillationOfLeavesAndStems:
        'Steam distillation of leaves and stems',
      steamDistillationOfTheFloweringPartOfThePlant:
        'Steam distillation of the flowering part of the plant',
      steamDistillationOfFlowerBudsStemsAndLeaves:
        'Steam distillation of flower buds, stems and leaves-',
      steamDistillationOfLeavesSeedsSapAndBark:
        'Steam distillation of leaves, seeds, sap and bark',
      steamDistillationOfBudsAndStems: 'Steam distillation of buds and stems',
      steamDistillationOfFlowersBudsAndLeaves:
        'Steam distillation of flowers, buds and leaves',
      coldPressingOfFruitPeel: 'Cold pressing of fruit peel',
      hydroDistillationFromRoots: 'Hidro distilare din radacini',
    },
    deleteModal: {
      title: 'Confirmați ștergerea produsului SRB',
      confirm: 'Da, ștergeți SRB',
      body: {
        top: 'Sunteți pe cale să ștergeți complet acest produs și informațiile despre acesta. SRB',
        text: 'Produsul nu va mai fi vizibil. Această acțiune nu poate fi anulată. SRB',
        areYouSure: 'Sunteți sigur că vreți să SRB',
        deleteHighlight: 'ștergeți permanent SRB',
        end: 'acest produs? SRB',
      },
    },
    confirmModal: {
      title: 'Confirmați modificările SRB',
      confirm: 'Am înțeles SRB',
      body: {
        text: 'Actualizarea unui produs activ poate avea un impact asupra anumitor utilizatori. SRB',
        secondText: 'Sunteți sigur că doriți să faceți asta? SRB',
      },
    },
  },
  // my landing page
  landingPage: {
    about: 'Despre SRB',
    whyJoin: 'Beneficii SRB',
    faq: 'FAQ SRB',
    liveEvent: 'Eveniment Live SRB',
    register: 'Înregistrare SRB',
    login: 'Autentificare SRB',
    changeLanguage: 'Schimbați limba SRB',
    title: 'O lume plină de magie și uleiuri esențiale doTERRA SRB',
    registration: 'Aplicați pentru înregistrare SRB',
    subtitle: 'Despre Wonderland Essentials SRB',
    content:
      'Cel mai mare DE CE al nostru este împuternicirea fiecărei ființe umane să exploreze legătura dintre cine este și cine poate și va deveni. De aceea, ne imaginăm o lume/țară a minunilor în care oamenii au puterea de a-și reînvia credințele și speranțele.  SRB',
    moreContent:
      'Toate acestea și multe altele prin creșterea emoțională cu ajutorul medicinei tradiționale chineze. Și cu o picătură de dragoste și ulei esențial. SRB',
    testimonialOne:
      'Da, "Suntem cu toții nebuni aici.", așa cum se spune în "Alice în Țara Minunilor". Da, "Suntem cu toții nebuni aici." Aici, dar mai ales acum. Însă, în același timp, suntem cu toții minunați. Lumea modernă are mai mult de 7 minuni. Are 7,9 miliarde de ... minuni ... de oameni. SRB',
    author: 'Alice Vacian SRB',
    testimonialSecond:
      'Sunt Alice Vacian, psihoterapeut, avocat doTERRA, un om stabilit în Țara Minunilor. Sunt acasă. Și sunt aici pentru a te ajuta să ajungi acasă. Să ne întoarcem cu toții la esență SRB',
    teamTitle: 'ME=WE = Wonderland Essential SRB',
    teamContent:
      'Povestea noastră este o poveste despre când EU (ME) devine NOI (WE). Unde WE este acronimul pentru Wonderland Essentials. Ne simțim binecuvântați că avem acest loc fericit pe care să îl numim acasă și că avem atât de mulți oameni minunați pe care să îi numim familie. Faceți cunoștință cu familia Wonderland Essentials. SRB',
    benefits: {
      title: 'Beneficii de a fi înrolat în echipa Wonderland Essentials SRB',
      web: {
        title: 'Acces exclusiv la aplicația web SRB',
        content:
          'Tu și numai tu ai acces la aplicația web, unde vei găsi informații bazate pe expertiza specialiștilor menite să te ajute să dobândești bunăstarea fizică și emoțională. Plus protocoale, recomandări, evenimente private, informații despre uleiurile DOTERRA. Alături de cele mai bune practici, DIY-uri, rețeta săptămânii, cursuri, resurse de marketing și multe altele. SRB',
      },
      oilProtocols: {
        title: 'Protocoale actualizate săptămânal SRB',
        content:
          'Tu și numai tu ai acces non-stop la recomandări detaliate pentru afecțiuni de bază. Indiferent ce oră este, mai ales dacă este o situație de urgență, vei accesa cele mai bune practici de administrare fizică și emoțională (Pst!, suntem printre puținii care oferă așa ceva). Oil Wiki în buzunarul tău, practic.  SRB',
      },
      events: {
        title: 'Evenimente private săptămânale și lunare SRB',
        startsIn: 'Starts in  SRB',
        years: ' ani SRB',
        months: ' luni SRB',
        days: ' zile SRB',
        hours: ' ore SRB',
        minutes: ' minute SRB',
        eventIn: '* Acest eveniment este în limba  SRB',
        content:
          'Tu și numai tu ai acces la seturi de prezentări de la una până la trei ore și întâlniri Zoom. Vei participa la tabere de uleiuri - evenimente de o săptămână în care abordăm teme precum iubirea de sine, întrebări și răspunsuri, recomandări personalizate - și vei învăța cum să încorporezi uleiurile esențiale în rutina ta zilnică. SRB',
      },
      support: {
        title: 'Informații complete și detaliate despre uleiuri SRB',
        content:
          'Tu și numai tu ai acces la descrierea completă a uleiurilor, modul în care sunt obținute, beneficii, metode de aplicare, ingrediente și precauții. De asemenea, ai acces la informații colectate din surse de încredere, cărți specializate, toate filtrate de noi. Te asigurăm că acesta este genul de informații pe care nu le găsești pe Google.  SRB',
      },
    },

    notConvinced: {
      title: 'Încă nu ești convins? SRB',
      content:
        'Aplicați pentru înregistrare și discutați cu consultanții noștri. SRB',
      boldContent: 'E uleios de uşor. SRB',
      button: 'Aplicați pentru înregistrare SRB',
    },

    askedQuestions: {
      title: 'Întrebări Frecvente SRB',
      text: 'Nu ai găsit răspunsul la întrebarea ta? Te rugăm să ne scrii la SRB',
      website: ' support@wonderlandessentials.com SRB',
    },

    clientsOpinion: {
      title: 'Ce spun alții SRB',
      content:
        'Risus ultricies tristique nulla aliquet.Est placerat in egestas erat imperdiet sed euismod nisirisus commodo viverra maecenas accumsan lacus vel facilisis. SRB',
      author: 'Raquel Velasquez SRB',
    },

    events: {
      title: 'Essential Oils Fundamentals SRB',
      content:
        'Orci dapibus ultrices in iaculis nunc sed augue lacus. Porta non pulvinar neque laoreet suspendisse. Eu nisl nunc mi ipsum faucibus. Diam maecenas ultricies mi eget mauris pharetra et. SRB',
      button: 'Add to Your Callendar SRB',
    },
    footer: {
      text: 'Bine ai venit acasă. Bine ai venit în Wonderland Essentials. O lume plină de uleiuri esențiale doTERRA și magie - magia devenirii.  SRB',
      doTERRAWebsite: 'Website oficial doTERRA SRB',
      cookie: 'Politica Cookie SRB',
      poweredBy: 'Powered by', //new
      privacy: 'Politica de confidențialitate SRB',
      content: '©2021 Wonderland Essentials. Toate drepturile rezervate. SRB',
      disclaimer: 'Disclaimer SRB',
    },
  },
  // events
  eventsPage: {
    pageTitle: 'Evenimente SRB',
    addEvent: '+ Creați un eveniment nou SRB',
    showing: {
      pre: 'Arata SRB',
      post: 'Eveniment SRB',
      plural: 's SRB',
      none: 'Nu există evenimente SRB',
    },
    eventAdded: 'Evenimente adăugate cu succes. SRB',
    eventNotSaved: 'Evenimentul nu a putut fi salvat. SRB',
    addEventTitle: 'Adăugați un eveniment SRB',
    editEventTitle: 'Editați evenimentul SRB',
    getEventsError: 'Nu se pot obține evenimente. SRB',
    noEvents: 'Nu există evenimente SRB',
    thisWeek: 'Urmează săptămâna aceasta SRB',
    thisMonth: 'Urmează luna aceasta SRB',
    nextMonth: 'Urmează luna viitoare SRB',
    updateEvent: 'Actualizați evenimentul SRB',
    selectEventLanguage: 'Selectați limba evenimentului SRB',
    selectLanguage: 'Selectați limba evenimentului SRB',
    title: 'Titlu SRB',
    altName: 'Nume alternativ SRB',
    category: 'Categorie SRB',
    description: 'Descriere SRB',
    translatedBy: 'Tradus de SRB',
    imageRequired: 'Vă rugăm să selectați o imagine de eveniment. SRB',
  },
  // single event page
  eventPage: {
    eventTitle: 'Titlul evenimentului SRB',
    failedToFetch: 'Nu s-au putut prelua datele despre evenimente. SRB',
    addEventToMyCallendar: 'Adaugă evenimentul în calendar SRB',
    eventRestrictions: 'Restricții SRB',
    eventRecurrence: 'Recurență SRB',
    addZoomlink: 'Adaugă link de Zoom SRB',
    eventDescription: 'Descriere SRB',
    eventOccurences: 'Apariții SRB',
    organizerAndGuests: 'Organizator și invitați SRB',
    deleteEvent: 'Șterge evenimentul SRB',
    editEvent: 'Editați evenimentul SRB',
    editEventTitle: 'Actualizați evenimentul SRB',
    eventLink: 'Link eveniment:  SRB',
    from: 'De la SRB',
    to: 'la SRB',
    customMobileButton: 'Custom mobile button text',
    customMobileButtonPlaceholder: 'Join with Zoom SRB',
    toastify: {
      eventUpdated: 'Evenimentul a fost actualizat. SRB',
      eventNotUpdated: 'Evenimentul nu poate fi actualizat. SRB',
      eventDeleted: 'Evenimentul a fost actualizat. SRB',
      eventNotDeleted: 'Evenimentul nu se poate șterge. SRB',
    },
    errors: {
      weekRecurrence: 'Trebuie să alegeți cel puțin o zi. SRB',
      host: 'Gazda evenimentului este necesară. SRB',
      hostLength:
        'Câmpul organizatorului și al invitaților trebuie să aibă mai puțin de 65 de caractere. SRB',
      description: 'Descrierea este necesară. SRB',
    },
    breadcrumbs: {
      all: 'Toate Evenimentele SRB',
    },
    deleteModal: {
      title: 'Confirmați ștergerea evenimentului SRB',
      confirm: 'Da, șterge SRB',
      body: {
        text: 'Sunteți pe cale să ștergeți complet acest eveniment și informațiile sale.  SRB',
        secondText:
          'Evenimentul nu va mai fi vizibil. Această acțiune nu poate fi anulată. SRB',
        areYouSure: 'Sunteți sigur că doriți să SRB',
        deleteHighlight: 'ștergeți permanent SRB',
        end: 'acest eveniment? SRB',
      },
    },
    confirmModal: {
      title: 'Confirmați modificările SRB',
      confirm: 'Am înțeles SRB',
      body: {
        text: 'Actualizarea unui eveniment activ poate avea un impact asupra unor utilizatori. SRB',
        secondText: 'Sunteți sigur că doriți să faceți asta? SRB',
      },
    },
    customRecurrence: {
      title: 'Recurență personalizată SRB',
      confirm: 'Terminat SRB',
      repeat: 'Repetă în fiecare SRB',
      repeatOn: 'Repetă la SRB',
      includeWeekends: 'Includeți weekendurile SRB',
      repeatInterval: 'Intervalul de repetiție SRB',
      daily: {
        dailyUntil: 'Zilnic, până la SRB',
        dailyAfter: 'Zilnic,  SRB',
        dailyTimes: ' ori. SRB',
        dailyNever: 'Zilnic SRB',
      },
      monthly: {
        monthlyUntil: 'În fiecare lună, până la  SRB',
        monthlyAfter: 'Lunar,  SRB',
        monthlyTimes: ' ori. SRB',
        monthlyNever: 'Lunar SRB',
      },
      yearly: {
        yearlyUntil: 'În fiecare an, până la SRB',
        yearlyAfter: 'Anual,  SRB',
        yearlyTimes: ' ori. SRB',
        yearlyNever: 'Anual SRB',
      },
      ends: 'Se termină SRB',
      never: 'Niciodată SRB',
      on: 'La SRB',
      after: 'După SRB',
      event: ' eveniment SRB',
    },
  },
  // protocols
  protocolsPage: {
    pageTitle: 'Protocoale SRB',
    addProtocol: '+ Adăugați Protocol SRB',
    favoriteText: 'Arată doar favorite SRB',
    showing: {
      pre: 'Arată SRB',
      post: 'Protocol SRB',
      plural: 's SRB',
      none: 'Fără Protocoale SRB',
    },
    protocolAdded: 'Protocoale adăugate cu succes. SRB',
    protocolNotSaved: 'Protocolul nu a putut fi salvat. SRB',
    addProtocolTitle: 'Create New Protocol SRB',
    addProtocolButton: 'Creați un protocol nou SRB',
    selectProtocolLanguage: 'Selectați limba protocolului SRB',
    selectLanguage: 'Selectați limba protocolului SRB',
    title: 'Titlul protocolului SRB',
    altName: 'Nume alternativ SRB',
    category: 'Categoria protocolului SRB',
    description: 'Descriere SRB',
    ingredients: 'Ingrediente SRB',
    benefits: 'Beneficii SRB',
    cautions: 'Precauții SRB',
    translatedBy: 'Mulțumiri SRB',
    imageRequired: 'Vă rugăm să selectați o imagine de protocol. SRB',
  },
  // single protocol page
  protocolPage: {
    failedToFetch: 'Nu s-au putut prelua datele protocolului. SRB',
    breadcrumbs: {
      all: 'Toate Protocoalele SRB',
    },
    selectLanguage: 'Selectați limba protocolului SRB',
    faledToFetchSimilar: 'Nu s-au putut obține protocoale similare. SRB',
    updatedProtocol: 'Protocolul a fost actualizat cu succes. SRB',
    deletedProtocol: 'Protocolul a fost șters cu succes. SRB',
    updateError: 'Protocolul nu poate fi actualizat SRB',
    deleteError: 'Protocolul nu poate fi șters SRB',
    updated: 'Protocolul a fost actualizat cu succes. SRB',
    failedUpdate: 'Nu s-au putut actualiza detaliile protocolului. SRB',
    editProtocol: 'Editați protocolul SRB',
    addProtocolTitle: 'Creați un protocol nou SRB',
    updateProtocol: 'Actualizați protocolul SRB',
    deleteProtocol: 'Ștergeți protocolul SRB',
    title: 'Titlul protocolului SRB',
    altName: 'Nume alternativ SRB',
    physical: 'Fizic SRB',
    emotional: 'Emoţional SRB',
    altSearchTerms: 'Termeni alternativi de căutare SRB',
    placeholderSearchTerms: 'Adăugați termeni de căutare SRB',
    topicalRecommendation: 'Recomandare Topică SRB',
    aromaticalRecommendation: 'Recomandare Aromatică SRB',
    internalRecommendation: 'Recomandare Internă SRB',
    additionalRecommendations: 'RECOMANDĂRI SUPLIMENTARE SRB',
    protocolFieldRequired: 'Este necesară descrierea. SRB',
    protocolDisclamer:
      '*Aceste informații reprezintă o compilație de sugestii făcute de cei care au folosit uleiuri esențiale și nu intentioneaza a subtitui sfatul unui medic profesionist. Este un document cu caracter informativ din experiențe personale și trebuiesc tratate ca atare. Produsele și metodele recomandate nu sunt destinate să diagnosticheze, să trateze, să vindece sau să prevină orice boală și nici nu intenționează să înlocuiască ajutorul medical adecvat. SRB',
    physicalTopicalRecommendation: 'Recomandare Fizică Topică SRB',
    physicalAromaticalRecommendation: 'Recomandare Fizică Aromatică SRB',
    physicalInternalRecommendation: 'Recomandare Fizică Internă SRB',
    physicalAdditionalRecommendation: 'Recomandări Fizice Suplimentare SRB',
    addPhysicalProtocol: 'Adăugați Protocol Fizic SRB',
    emotionalTopicalRecommendation: 'Recomandare Emoțională Topică SRB',
    emotionalAromaticalRecommendation: 'Recomandare Emoțională Aromatică SRB',
    emotionalInternalRecommendation: 'Recomandare Emoțională Internă SRB',
    emotionalAdditionalRecommendations:
      'Recomandări Emoționale Suplimentare SRB',
    addEmotionalProtocol: 'Adăugați protocol emoțional SRB',
    noProtocolType: 'Trebuie să alegeți cel puțin un tip de protocol. SRB',
    deleteModal: {
      title: 'Confirmă ștergerea SRB',
      confirm: 'Da, șterge SRB',
      body: {
        top: 'Sunteți pe cale să ștergeți complet protocolul și informațiile sale. Această acțiune nu poate fi anulată. SRB',
        areYouSure: 'Sunteți sigur că doriți să SRB',
        deleteHighlight: 'ștergeți permanent SRB',
        end: 'acest protocol? SRB',
      },
    },
    zonesDialogButton: 'Zones', //new
    zonesModal: {
      title: 'Application Zones', //new
      confirm: 'Save', //new
      solesOfFeet: 'Soles of feet', //new
      neck: 'Neck', //new
      forhead: 'Forhead', //new
      armpits: 'Armpits', //new
      solarPlexus: 'Solar Plexus', //new
      heart: 'Heart', //new
      sacrum: 'Sacrum', //new
      lombarRegion: 'Lombar region', //new
      sinuses: 'Sinuses', //new
      underTheNose: 'Under the nose', //new
      chest: 'Chest', //new
      backOfNeck: 'Back of neck', //new
      spine: 'Spine', //new
      diaphragm: 'Diaphragm', //new
      back: 'Back', //new
      topOfHead: 'Top of head', //new
      aroundBellyButton: 'Around belly button', //new
      lowerAbdomen: 'Lower abdomen', //new
      insideWristCreses: 'Inside wrist creses', //new
      temples: 'Temples', //new
      baseOfTheSkull: 'Base of the skull', //new
      behindTheEars: 'Behind the ears', //new
      alongTheArms: 'Along the arms', //new
      abdomen: 'Abdomen', //new
      shoulders: 'Shoulders', //new
      kidneys: 'Kidneys', //new
      midlineOfTheBelly: 'Midline of the belly', //new
      insideElbowCreases: 'Inside elbow creases', //new
      outerLineOfTheJaw: 'Outer line of the jaw', //new
      sternBone: 'Stern bone', //new
      outsideWristCreases: 'Outside wrist creases', //new
      innerThighArea: 'Inner thigh area', //new
      theBaseOfTheNeck: 'The base of the neck', //new
      tailBone: 'Tailbone', //new
      feet: 'Feet', //new
      behindTheKnees: 'Behind the knees', //new
      liverArea: 'Liver area', //new
    },
    confirmModal: {
      title: 'Confirmați modificările SRB',
      confirm: 'Am înțeles SRB',
      body: {
        text: 'Actualizarea unui protocol activ poate avea un impact asupra unor utilizatori. Ești sigur că vrei să faci asta? SRB',
      },
    },
  },
  // 404 not found page
  notFoundPage: {
    notFound: 'Pagina nu a fost găsită SRB',
    title: 'Hopa! Se pare că s-a spart ceva. SRB',
    content: 'Sperăm că nu este o sticlă de ulei esențial... SRB',
    button: 'Înapoi acasă SRB',
  },
  // contact page
  contactPage: {
    title: 'Hai să ne auzim SRB',
    text: 'Completează formularul. Este super ușor. SRB',
    content: 'Ne puteți contacta și prin email la  SRB',
    website: 'support@wonderlandessentials.com SRB',
    contactEmail: 'mailto:support@wonderlandessentials.com SRB',
    button: 'Trimite Mesaj SRB',
    typeEmail: 'Adresa de email SRB',
    textAreaLabel: 'Spune-ne pe scurt cum te putem ajuta SRB',
    messageSent: 'Mesajul tău a fost trimis. SRB',
  },
  // request error messages
  errors: {
    generic: {
      serverError: 'Server error. SRB',
      sessionExpired: 'Sesiunea a expirat. Conectați-vă din nou. SRB',
      notAllowed: 'Nu aveți acces pentru a vizualiza această pagină SRB',
    },
    auth: {
      login: {
        notFound: 'Utilizatorul nu există . SRB',
        wrongPass: 'Parola incorectă. SRB',
      },
    },
    products: {
      badRequest: 'Bad request. SRB',
      notFound: 'Produsul nu a fost găsit. SRB',
    },
    protocols: {
      badRequest: 'Bad request. SRB',
      notFound: 'Protocolul nu a fost găsit. SRB',
    },
    events: {
      badRequest: 'Bad request. SRB',
      notFound: 'Evenimentul nu a fost găsit. SRB',
    },
    users: {
      badRequest: 'Bad request. SRB',
      notFound: 'Utilizatorul nu a fost găsit. SRB',
    },
  },
  dashboard: {
    title: 'Dashboard SRB', //new
    statistics: 'Statistics SRB',
    blendOfTheMonth: 'Blend of the month SRB', //new
    products: 'doTERRA Products SRB', //new
    protocols: 'Essential Oils Protocols SRB', //new
    languages: 'Languages SRB', //new
    readMore: 'Read more SRB', //new
    blendAdd: '+ Add SRB', //new
    blendEdit: 'Editează', //new
    allBlends: 'All blends SRB', //new
    noBlends: 'No blends SRB', //new
    blendDisclamer:
      '* SRB Aceste informații reprezintă o compilație de sugestii făcute de cei care au folosit uleiuri esențiale și nu intentioneaza a substitui sfatul unui medic profesionist. Este un document cu caracter informativ sintetizat din experiențe personale și trebuie tratate ca atare. Produsele și metodele recomandate nu sunt destinate să diagnosticheze, să trateze, să vindece sau să prevină vreo boală și nici nu intenționează să înlocuiască ajutorul medical adecvat.',
    applicationZones: 'Application zones',
    frequency: 'Frequency',
    duration: 'Duration',
    modal: {
      new: 'New monthly offer SRB', //new
      editOffer: 'Edit monthly offer SRB', //new
      addBlend: 'Adaugă mix nou', //new
      editBlend: 'Select Blend of the Month', //new
      deleteBlend: 'Delete Blend', //new
      selectLanguage: 'Select language SRB', //new
      selectBlend: 'Select blend SRB', //new
      title: 'Title SRB', //new
      url: 'URL SRB', //new
      ok: 'Save SRB', //new
    },
  },
  deleteModal: {
    title: 'Confirm Delete', //new
    confirm: 'Yes, Delete it', //new
    body: {
      top: 'You are about to completely delete the blend and its information. This action cannot be undone.', //new
      areYouSure: 'Are you sure you want to', //new
      deleteHighlight: 'permanently delete', //new
      end: 'this blend?', //new
    },
  },
  editor: {
    generic: {
      add: 'Add',
      cancel: 'Cancel',
    },
    link: {
      linkTitle: 'Titlu SRB', //new
      linkTarget: 'Link  SRB', //new
      linkTargetOption: 'Deschide fereastră separată SRB', //new
      link: 'Link SRB', //new
      unlink: 'Unlink SRB', //new
    },
  },
};
