import { stringsRO } from './ro';
import { stringsEN } from './en';
import { stringsSE } from './se';

export const resources = {
  en: {
    translation: stringsEN,
  },
  ro: {
    translation: stringsRO,
  },
  se: {
    translation: stringsSE,
  },
};
