import { LocalStorage } from 'types';

export const defaultStorage: LocalStorage = {
  we_lang: 'en',
  account: null,
  token: null,
};

export const initStorage = () => {
  let initialStorage = { ...defaultStorage };
  for (let key of Object.keys(defaultStorage) as string[]) {
    let item = getStorageItem(key);
    if (!item && defaultStorage[key] !== null) {
      setStorageItem(key, defaultStorage[key]);
    } else
      initialStorage = {
        ...initialStorage,
        [key]: item,
      };
  }
  return initialStorage;
};

export const readStorage = () => {
  let items: LocalStorage = { ...defaultStorage };
  for (let key of Object.keys(localStorage) as string[])
    items = { ...items, [key]: getStorageItem(key) };
  return items;
};

export const resetStorage = () => {
  for (let key of Object.keys(defaultStorage) as string[])
    if (key !== 'we_lang') setStorageItem(key, defaultStorage[key]);
};

export const getStorageItem = (key: string) => {
  const value = localStorage.getItem(key) as string;
  if (value) {
    try {
      var parsed = JSON.parse(value) as object;
      return parsed;
    } catch (e) {
      return value;
    }
  } else return null;
};

export const setStorageItem = (key: string, value: string | Object | null) => {
  switch (typeof value) {
    case 'object':
      localStorage.setItem(key, JSON.stringify(value));
      break;
    case 'string':
      localStorage.setItem(key, value);
      break;
    default:
      /* @ts-ignore */
      localStorage.setItem(key, null);
      break;
  }
};
