import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  createContext,
} from 'react';
import { LocalStorage } from 'types';
import {
  defaultStorage,
  initStorage,
  readStorage,
  setStorageItem,
} from 'utils/localStorage';

interface Props {
  children: React.ReactNode;
}

export const Storage = createContext<LocalStorage>(initStorage());
export const Dispatch = createContext<
  (updates: Record<string, string | object | null>) => void
>((obj: Record<string, string | object | null>) => {});

export const Provider = ({ children }: Props) => {
  const [globalState, setGlobalState] = useState(initStorage());

  const dispatchRef =
    useRef<(updates: Record<string, string | object | null>) => void>();
  dispatchRef.current = (updates: Record<string, string | object | null>) => {
    setGlobalState({ ...globalState, ...updates });
    for (let key of Object.keys(updates) as string[]) {
      setStorageItem(key, updates[key]);
    }
  };

  const dispatch = useCallback(
    (updates: Record<string, string | object | null>) =>
      dispatchRef.current?.({ ...updates }),
    // eslint-disable-next-line
    [dispatchRef.current]
  );

  const handleEvent = (e: StorageEvent) => {
    if (e.key && Object.keys(defaultStorage).includes(e.key)) {
      // console.log('Storage Event triggered', e.key);
      setGlobalState(readStorage());
      window.location.reload();
    }
  };

  //ON MOUNT ADD EVENT LISTENER
  useEffect(() => {
    window.addEventListener('storage', handleEvent);
    return () => {
      window.removeEventListener('storage', handleEvent);
    };
  }, []);

  return (
    <Storage.Provider value={globalState}>
      <Dispatch.Provider value={dispatch}>{children}</Dispatch.Provider>
    </Storage.Provider>
  );
};
