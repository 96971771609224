export const stringsRO = {
  schema: {
    firstName: {
      invalid: 'Vă rugăm să introduceți un nume valid.',
      required: 'Prenumele este obligatoriu.',
      min: 'Prenumele trebuie să aibă cel puțin 2 caractere.',
      max: 'Prenumele nu poate depăși 55 de caractere.',
    },
    lastName: {
      invalid: 'Vă rugăm să introduceți un nume valid.',
      required: 'Numele este obligatoriu.',
      min: 'Numele trebuie să aibă cel puțin 2 caractere.',
      max: 'Numele nu poate depăși 55 de caractere.',
    },
    email: {
      required: 'Adresa de email este obligatorie.',
      invalid: 'Adresa email invalidă.',
      inUse: 'Această adresă de email este deja în uz.',
    },
    country: {
      required: 'Țara este obligatorie.',
    },
    language: {
      required: 'Limba este obligatorie.',
    },
    title: {
      required: 'Titlul este obligatoriu.',
    },
    eventImage: {
      required: 'Imaginea evenimentului este obligatorie.',
    },
    zoomLink: {
      invalid: 'Link invalid.',
      required: 'Este necesar un link de Zoom.',
    },
    phone: {
      required: 'Este necesar un număr de telefon.',
      invalid: 'Număr de telefon invalid.',
    },
    doterraId: {
      required: 'doTERRA ID este obligatoriu.',
      invalid: 'doTERRA ID invalid.',
    },
    password: {
      required: 'Parola este obligatorie.',
      short: 'Parolă trebuie să conțină de cel puțin 8 caractere.',
      invalid: 'Cerințele privind parola nu sunt îndeplinite.',
    },
    generic: 'Acest camp este obligatoriu.',
  },
  // generics
  generic: {
    cancel: 'Anulare',
    forms: {
      firstName: 'Prenume',
      lastName: 'Nume',
      language: 'Limba',
      phone: 'Număr de telefon',
      country: 'Țara',
      doterraId: 'doTERRA ID',
      email: 'Adresa de email',
      password: 'Parola',
      typeHere: 'Scrie aici',
      search: 'Caută',
    },
  },
  // avatar dropdown header
  hello: 'Salut',
  homepage: 'Accesați homepage',
  myAccount: 'Contul meu',
  userManagement: 'Gestionarea utilizatorilor',
  contact: 'Contact',
  logout: 'Deconectați-vă',
  help: 'Ajutor',
  // navigation
  products: 'Produse',
  protocols: 'Protocoale',
  resources: 'Resurse',
  bookings: 'Programări',
  eduCare: ' eduCare',
  consultation: 'Consultații',
  events: 'Evenimente',
  search: 'Căutare',
  about: 'Despre',
  whyJoin: 'Beneficii',
  faq: 'FAQ',
  liveEvent: 'Live',
  login: 'Autentificare',
  changeLanguage: 'Schimbați limba',
  // login page
  loginTitle: 'Autentificare membru',
  loginSubtitle: 'O lume plină de magie și uleiuri esențiale doTERRA',
  typeEmail: 'Introduceți adresa de email',
  typePassword: 'Introduceți parola',
  forgotPassword: 'Ați uitat parola?',
  registerLink: 'Nu ai cont?',
  buttonLogin: 'Autentificare',
  // my account page
  myAccountPage: {
    removeAvatar: 'Eliminați imaginea',
    country: 'Țara de reședință',
    language: 'Selectează limba',
    doterraId: 'ID-ul doTERRA al utilizatorului',
    emailVerified: 'Email verificat',
    changePassword: 'Schimbați parola',
    update: 'Actualizați informațiile',
    deleteAccount: 'Stergeti contul',
    deletedAccount: 'Contul dumneavoastră a fost șters cu succes.',
    deleteModal: {
      topText:
        'Sunteți pe cale să vă ștergeți complet contul și toate informațiile acestuia.',
      bottomText: {
        firstPart: 'Sunteți sigur că doriți',
        redText: 'ștergea permanentă',
        secondPart: 'a contului dumneavoastră?',
      },
    },
  },
  //userManagement page
  usersManagement: {
    addUser: '+ Adaugă Utilizator',
    all: 'Toate',
    serverError: 'Server error',
    genericError: 'Something went wrong. Try refreshing the page.',
    showing: {
      pre: 'Se afișează',
      user: 'utilizator',
      plural: 'de utilizatori',
      none: 'Nici un utilizator',
    },
    pagination: {
      show: 'Arata',
      page: 'Pag',
      of: 'din',
    },
    table: {
      name: 'Nume',
      country: 'Tara',
      submissionDate: 'Data inregistrarii',
      assignedEnroller: 'Inrolator',
      doTERRAID: 'ID doTERRA',
      accountType: 'Tip cont',
      status: 'Status',
    },
  },

  userManagementModal: {
    addUser: 'Poți adaugă doar utilizatori',
    website: ' Înrolați în Wonderland Essentials.',
    enrolledStatus: 'Înrolat',
    content: 'Are un cont doTERRA sub Wonderland Essentials.',
    doterraId: 'doTERRA ID',
    enroller: 'Înrolator',
    account: 'Tipul contului',
    firstName: 'Prenume',
    lastName: 'Nume',
    emailAddress: 'Adresa de email',
    country: 'Țara',
    phone: 'Număr de telefon',
    createAccount: 'Creați un cont nou de utilizator',
    editAccount: 'Editare cont de utilizator',
    deleteAccount: 'Ștergere cont de utilizator',
    addedUser: 'Contul de utilizator a fost creat cu succes: ',
    updatedUser: 'Detalii de utilizator modificate cu succes pentru ',
    removedUser: 'Utilizator șters: ',
    confirmedUser: 'Utilizator confirmat cu succes',
    addButton: 'Creați un utilizator nou',
    updateButton: 'Actualizați informațiile',
    cancelButton: 'Anulare',
    statusLabel: 'Starea contului',
    accountType: {
      info: 'Ce sunt tipurile de cont?',
      admin: {
        type: 'Administrator',
        content:
          ' vede toate protocoalele, evenimentele și are acces la baza de date a utilizatorilor.',
      },
      consumer: {
        type: 'Client',
        content: ' vede protocoalele și evenimentele selectate.',
      },
    },
    accountStatus: {
      title: 'Ce este starea contului?',
      notContacted: {
        tag: 'Necontactat',
        content: 'Fără cont doTERRA și așteaptă să fie contactat.',
      },
      pending: {
        tag: 'În așteptare',
        content: 'Contactat și nu are încă un cont doTERRA.',
      },
      enrolled: {
        tag: 'Înrolat',
        content: 'Are un cont doTERRA sub Wonderland Essentials.',
      },
    },
    phoneNumber: 'Număr de telefon',
    deletedAccount: 'Contul dumneavoastră a fost șters cu succes.',
    deleteModal: {
      confirm: 'Confirmă ștergerea',
      topText:
        'Sunteți pe cale să ștergeți complet acest utilizator și toate informațiile sale. Această acțiune nu poate fi revocată.',
      bottomText: {
        firstPart: 'Sunteți sigur că vreți să ',
        redText: 'ștergeți complet',
        secondPart: ' contul acestui utilizator?',
      },
      deleteButton: 'Ștergere',
      cancelButton: 'Anulare',
    },
    errors: {
      createUser: 'Contul de utilizator nu a putut fi creat',
      confirmUser: 'Contul de utilizator nu a putut fi confirmat',
      email: 'Această adresă de email este deja folosită.',
      updateUser:
        'A apărut o eroare la actualizarea detaliilor utilizatorului.',
      doterraId: 'ID-ul doTERRA este utilizat de un alt cont.',
    },
  },
  // initial password setup page
  initialPasswordPage: {
    title: 'Bine ați venit pe Wonderland Essentials',
    subtitle:
      'Suntem foarte încântați că ați decis să vă alăturați echipei Wonderland Essentials. Sperăm ca în curând sa aflați și ce decizie excelentă ați luat!',
    text: 'Dar mai întâi, setați parola contului dumneavoastră!',
    yourPassword: 'Parolă dumneavoastră',
    confirmPassword: 'Confirmare parolă',
    update: 'ACTUALIZAȚI PAROLA',
    terms: {
      accept: 'Sunt de acord cu ',
      terms: 'termenii și condițiile',
    },
    required: 'Este necesar sa accepți termenii si condițiile',
  },
  // change password page
  chooseNewPasswordPage: {
    title: 'Alegeți o parolă nouă',
    passwordRequirements:
      'Parolele trebuie să aibă cel puțin 8 caractere și trebuie să conțină cel puțin 1 cifră, 1 literă mare, 1 literă mică și 1 caracter special.',
    oldPassword: 'Parolă veche',
    newPassword: 'Parolă nouă',
    confirmNewPassword: 'Confirmare parolă nouă',
    save: 'SALVARE PAROLĂ',
    confirm: {
      title: 'Parola a fost schimbată cu succes',
      loginBTN: 'AUTENTIFICARE',
    },
  },
  loginPage: {
    emailLabel: 'Adresă de email',
    emailInput: 'Introduceți adresa de email',
    passwordLabel: 'Parolă',
    passwordInput: 'Introduceți parola',
    forgotPassword: 'Ați uitat parola?',
    label: 'Devino membru',
  },
  // set register page
  registerPage: {
    title: 'Lasă-ne datele tale și te vom contacta',
    subtitle: 'De ce nu pot să-mi creez, pur și simpu, un cont? ',
    text: 'Pentru că noi credem în corectitudine. Credem că pentru a primi respect, trebuie mai întâi să-l oferim. Prin urmare, pentru a se potrivi cu modelul de afaceri doTERRA și cu alți avocați doTERRA, Wonderland Essentials acceptă doar membrii care nu au un cont doTERRA. ',
    information: 'Cum funcționează? ',
    textInformation:
      'Cineva te va contacta telefonic în termen de 24 de ore și te va ajuta cu răspunsuri și cu configurarea inițială.',
    firstName: 'Nume',
    lastName: 'Prenume',
    yourEmail: 'Adresă de email',
    countryOfResidence: 'Țară de reședința',
    phoneNumber: 'Număr de telefon',
    doTerraAccount: 'Nu am un cont doTERRA',
    termsAndConditions: 'Accept termenii și condițiile',
    registration: 'Aplicați pentru înregistrare',
    contact: '*Pentru orice alte întrebări va rugăm să ne scrieți la adresa ',
    website: 'support@wonderlandessentials.com',
    validations: {
      doterraAccount: 'Nu poți avea mai multe conturi doTERRA',
      termsAndConditions: 'Trebuie să accepti termenii și condițiile',
    },
  },
  //
  // set register page
  formSentConfirmationPage: {
    title: 'Cererea dumneavoastră a fost trimisă',
    subtitle:
      'Îți mulțumim pentru interesul tău de a face parte din echipa Wonderland Essentials.',
    text: 'Vom încerca să vă contactăm telefonic în 24 de ore. În caz contrar, vă rugăm să trimiteți un e-mail la ',
    website: 'support@wonderlandessentials.com',
    btnText: 'INAPOI LA WEBSITE',
  },
  // change password page
  changePassword: 'Schimbă parolă',
  passwordRequirements:
    'Parola trebuie să aibă cel puțin 8 caractere și trebuie să conțină cel puțin 1 cifră, 1 literă mare, 1 literă mica și 1 caracter special.',
  oldPassword: 'Parolă veche',
  newPassword: 'Parolă nouă',
  confirmNewPassword: 'Confirmă parolă nouă',
  confirmPasswordChange: 'SCHIMBĂ PAROLĂ',
  // form validation messages
  passwordsNoMatch: 'Parolele nu se potrivesc!',
  // error messages
  profileUpdateFail: `A apărut o problemă la actualizarea detaliilor contului. Încercați mai târziu.`,
  // success messages
  profileUpdated: `Profil actualizat cu succes.`,
  // forgot password page
  forgotPasswordPage: {
    text: 'Introduceți adresa de e-mail asociată contului dvs. și vom trimite un link pentru a vă reseta parola.',
    emailLabel: 'Adresă email',
    emailInput: 'Introdu adresă email',
    buttons: {
      continue: 'Continuă',
      login: 'Resetare parolă',
      cancel: 'Anulare',
    },
    linkSent:
      'Am trimis un e-mail la adresa de e-mail furnizată cu instrucțiuni suplimentare.',
    backToHomepage: 'Înapoi la Website',
    emailNotFound: 'Nu s-a găsit un cont cu această adresă de e-mail.',
    serverError:
      'A apărut o problemă la trimiterea link-ului de resetare a parolei.',
  },

  // products
  productsPage: {
    pageTitle: 'Produse',
    addProduct: '+ Produs nou',
    showing: {
      pre: 'Se afișează',
      post: 'Produs',
      plural: 'Produse',
      none: 'Nici un produs',
    },
    categories: {
      singleOils: 'Uleiuri',
      blends: 'Amestecuri',
      supplements: 'Suplimente',
      personalCare: 'Îngrijire personală',
      cleaning: 'Curățenie',
      oilKits: 'Kituri',
      diffusers: 'Difuzoare',
      accesories: 'Accesorii',
    },
    productAdded: 'Produse adăugate cu succes.',
    productNotSaved: 'Produsul nu a putut fi salvat.',
    addProductTitle: 'Creați un produs nou',
    addProductButton: 'Creați produs',
    selectProductLanguage: 'Selectați limba produsului',
    applicationMethod: 'Metoda de aplicare',
    selectLanguage: 'Selectați limba produsului',
    a: 'A - Aromatic',
    t: 'T - Topic',
    i: 'I - Intern',
    h: 'H - Gospodărie',
    skinSensitivity: 'Sensibilitatea pielii',
    n: 'N - Nediluat',
    s: 'S - Sensibilă',
    d: 'D - Diluați',
    title: 'Titlul produsului',
    altName: 'Nume alternativ',
    category: 'Categoria produsului',
    extractionMethod: 'Metoda de extracție',
    description: 'Descriere',
    ingredients: 'Ingrediente',
    benefits: 'Beneficii',
    cautions: 'Precauții',
    translatedBy: 'Mulțumiri',
    imageRequired: 'Vă rugăm să selectați o imagine de produs.',
    videoUrl: 'Link embed YouTube',
  },
  // single product page
  productPage: {
    failedToFetch: 'Nu s-au putut prelua datele despre produse.',
    breadcrumbs: {
      all: 'Toate produsele',
    },
    selectProductLanguage: 'Selectați limba produsului',
    faledToFetchSimilar: 'Nu s-au putut obține produse similare.',
    deletedProduct: 'Produsul a fost șters.',
    imageRequired: 'Vă rugăm să selectați o imagine de produs.',
    updated: 'Produsul a fost actualizat cu succes.',
    duplicate: 'Exista deja un produs cu acest nume.',
    failedUpdate: 'Nu s-au putut actualiza detaliile produsului.',
    failedDelete: 'Produsul nu a putut fi șters.',
    editProduct: 'Editați produsul',
    addProductTitle: 'Creați un produs nou',
    updateProduct: 'Actualizați produsul',
    deleteProduct: 'Ștergeți produsul',
    initials: {
      a: 'A',
      t: 'T',
      i: 'I',
      n: 'N',
      s: 'S',
      d: 'D',
      h: 'H',
    },
    title: 'Titlul produsului',
    altName: 'Nume alternativ',
    category: 'Categorie produs',
    extractionMethod: 'Metoda de extracție',
    description: 'Descriere',
    ingredients: 'Ingrediente',
    benefits: 'Beneficii',
    cautions: 'Precauții',
    similarTitle: 'Descoperiți produse similare',
    noSimilarProducts: 'Nu există produse similare',
    extraction: {
      none: 'Niciuna',
      notApplicable: 'Nu se aplică',
      steamDistillationOfFlowers: 'Distilare cu aburi a florilor',
      steamDistillationOfLeaves: 'Distilare cu aburi a frunzelor',
      steamDistillationOfFruit: 'Distilare cu aburi a fructelor',
      steamDistillationOfRoots: 'Distilare cu aburi a rădăcinilor',
      steamDistillationOfRhizomes: 'Distilare cu aburi a rizomilor',
      steamDistillationOfWood: 'Distilare cu aburi a lemnului',
      steamDistillationOfResin: 'Distilare cu aburi a rășinii',
      steamDistillationOfSap: 'Distilare cu aburi a sevei',
      steamDistillationOfSeeds: 'Distilare cu aburi a semințelor',
      steamDistillationOfBark: 'Distilare cu aburi a scoarței',
      steamDistillationOfLeavesAndFlowers:
        'Distilare cu aburi a frunzelor și florilor',
      steamDistillationOfNeedlesAndTwigs:
        'Distilare cu aburi a acelor și lăstarilor tineri',
      steamDistillationOfFruitsLeavesAndSeeds:
        'Distilare cu aburi a fructelor, frunzelor și semințelor',
      steamDistillationOfLeavesAndYoungBranches:
        'Distilare cu aburi a frunzelor și ramurilor tinere',
      steamDistillationOfLeavesAndPlantTips:
        'Distilare cu aburi a frunzelor și vârfurilor plantei',
      steamDistillationOfLeavesAndStems:
        'Distilare cu aburi a frunzelor și crengilor',
      steamDistillationOfTheFloweringPartOfThePlant:
        'Distilare cu aburi a părții înflorite a plantei',
      steamDistillationOfFlowerBudsStemsAndLeaves:
        'Distilare cu aburi a mugurilor și lăstarilor tineri',
      steamDistillationOfLeavesSeedsSapAndBark:
        'Distilare cu aburi a frunzelor, semințelor, sevei și scoarței',
      steamDistillationOfBudsAndStems:
        'Distilare cu aburi a mugurilor de flori, lăstarilor și frunzelor',
      steamDistillationOfFlowersBudsAndLeaves:
        'Distilare cu aburi a florilor, mugurilor și frunzelor',
      coldPressingOfFruitPeel: 'Presare la rece a coajei',
      hydroDistillationFromRoots: 'Hidro distilare din rădăcini',
      absoluteFromFlowers: 'Absolut din flori',
      absoluteFromBeansOrPods: 'Absolut din boabe/pastai',
    },
    deleteModal: {
      title: 'Confirmați ștergerea produsului',
      confirm: 'Da, ștergeți',
      body: {
        top: 'Sunteți pe cale să ștergeți complet acest produs și informațiile despre acesta.',
        text: 'Produsul nu va mai fi vizibil. Această acțiune nu poate fi anulată.',
        areYouSure: 'Sunteți sigur că vreți să',
        deleteHighlight: 'ștergeți permanent',
        end: 'acest produs?',
      },
    },
    confirmModal: {
      title: 'Confirmați modificările',
      confirm: 'Am înțeles',
      body: {
        text: 'Actualizarea unui produs activ poate avea un impact asupra anumitor utilizatori.',
        secondText: 'Sunteți sigur că doriți să faceți asta?',
      },
    },
  },
  // my landing page
  landingPage: {
    about: 'Despre',
    whyJoin: 'Beneficii',
    faq: 'FAQ',
    liveEvent: 'Live',
    register: 'Înregistrare',
    login: 'Autentificare',
    changeLanguage: 'Schimbați limba',
    title: 'O lume plină de magie și uleiuri esențiale doTERRA',
    registration: 'Aplicați pentru înregistrare',
    subtitle: 'Despre Wonderland Essentials',
    testimonialOne:
      '"Mă aflu în fața unui munte înalt, al sacrificiilor făcute de un milion de femei înaintea mea, și mă întreb cum aș putea să înalț acest munte, ce sacrificii aș putea face și eu, astfel încât femeilor care vin în urma mea să le las moștenire un munte atât de înalt încât, de pe vârful lui, orizonturile lor să fie mult mai largi." Rupi Kaur',
    content:
      'Sunt Alice Vacian, psihoterapeut spiritual, aromaterapeut emoțional, un om cu iubire de oameni stabilit în Țara Minunilor. Sunt acasă. Și sunt aici pentru a te ajuta să ajungi acasă. Să ne întoarcem cu toții la esență.',
    author: 'Alice Vacian',
    teamTitle: 'ME=WE=Wonderland Essential',
    teamContent:
      'Povestea noastră este o poveste despre când EU (ME) devine NOI (WE), Wonderland Essentials. Ne simțim binecuvântați că am construit și continuăm sa dezvoltăm acest loc prielnic pe care să îl numim acasă și că împreună cu noi avem atât de mulți oameni minunați pe care să îi numim familie de suflet. Faceți cunoștință cu familia Wonderland Essentials.',
    benefits: {
      title: 'Beneficii de a fi înrolat în echipa Wonderland Essentials',
      web: {
        title: 'Acces exclusiv la aplicația web',
        content:
          'Tu și numai tu ai acces la aplicația web, unde vei găsi informații bazate pe expertiza specialiștilor menite să te ajute să dobândești bunăstarea fizică și emoțională. Plus protocoale, recomandări, evenimente private, informații despre uleiurile DOTERRA. Alături de cele mai bune practici, DIY-uri, rețeta săptămânii, cursuri, resurse de marketing și multe altele.',
      },
      oilProtocols: {
        title: 'Protocoale actualizate săptămânal',
        content:
          'Tu și numai tu ai acces non-stop la recomandări detaliate pentru afecțiuni de bază. Indiferent ce oră este, mai ales dacă este o situație de urgență, vei accesa cele mai bune practici de administrare fizică și emoțională (Pst!, suntem printre puținii care oferă așa ceva). Oil Wiki în buzunarul tău, practic. ',
      },

      events: {
        title: 'Evenimente private săptămânale și lunare',
        startsIn: 'Începe peste ',
        years: ' ani',
        months: ' luni',
        day: ' zi',
        days: ' zile',
        hours: ' ore',
        minutes: ' minute',
        eventIn: '* Acest eveniment este în limba română',
        content:
          'Tu și numai tu ai acces la seturi de prezentări de la una până la trei ore și întâlniri Zoom. Vei participa la tabere de uleiuri - evenimente de o săptămână în care abordăm teme precum iubirea de sine, întrebări și răspunsuri, recomandări personalizate - și vei învăța cum să încorporezi uleiurile esențiale în rutina ta zilnică.',
      },
      support: {
        title: 'Informații complete și detaliate despre uleiuri',
        content:
          'Tu și numai tu ai acces la descrierea completă a uleiurilor, modul în care sunt obținute, beneficii, metode de aplicare, ingrediente și precauții. De asemenea, ai acces la informații colectate din surse de încredere, cărți specializate, toate filtrate de noi. Te asigurăm că acesta este genul de informații pe care nu le găsești pe Google. ',
      },
    },

    notConvinced: {
      title: 'Încă nu ești convins?',
      content:
        'Aplicați pentru înregistrare și discutați cu consultanții noștri.',
      boldContent: 'E uleios de uşor.',
      button: 'Aplicați pentru înregistrare',
    },

    askedQuestions: {
      title: 'Întrebări Frecvente',
      text: 'Nu ai găsit răspunsul la întrebarea ta? Te rugăm să ne scrii la',
      website: ' support@wonderlandessentials.com',
    },

    clientsOpinion: {
      title: 'Ce spun alții',
      content:
        'Risus ultricies tristique nulla aliquet.Est placerat in egestas erat imperdiet sed euismod nisirisus commodo viverra maecenas accumsan lacus vel facilisis.',
      author: 'Raquel Velasquez',
    },

    consultations: {
      title: 'Consultații',
      content:
        'Ai ajuns într-un loc special, aici îți poți face programare pentru o întâlnire unu la unu cu Alice, o consultație de 15 minute în care vei primi o recomandare personalizată care să te ajute să treci prin provocările din acest moment. Sunt doua secțiuni, pentru vorbitorii de limba română și pentru vorbitorii de limba engleză. Citește cu atenție instrucțiunile înainte de-ți face o programare. Vino cu inima deschisă!',
    },

    events: {
      title: 'Essential Oils Fundamentals',
      content:
        'Orci dapibus ultrices in iaculis nunc sed augue lacus. Porta non pulvinar neque laoreet suspendisse. Eu nisl nunc mi ipsum faucibus. Diam maecenas ultricies mi eget mauris pharetra et.',
      button: 'Adaugă în calendar',
    },
    footer: {
      text: 'Bine ai venit acasă. Bine ai venit în Wonderland Essentials. O lume plină de uleiuri esențiale doTERRA și magie - magia devenirii. ',
      doTERRAWebsite: 'Website oficial doTERRA',
      cookie: 'Politica Cookie',
      privacy: 'Politica de confidențialitate',
      terms: 'Termenii si Condițiile',
      poweredBy: 'Powered by',
      content: 'Wonderland Essentials. Toate drepturile rezervate.',
      disclaimer: 'Disclaimer',
    },
  },
  // events
  eventsPage: {
    pageTitle: 'Evenimente',
    addEvent: '+ Eveniment nou',
    showing: {
      pre: 'Arata',
      post: 'Eveniment',
      plural: 's',
      none: 'Nu există evenimente',
    },
    eventAdded: 'Evenimente adăugate cu succes.',
    eventNotSaved: 'Evenimentul nu a putut fi salvat.',
    addEventTitle: 'Adăugați un eveniment',
    editEventTitle: 'Editați evenimentul',
    getEventsError: 'Nu se pot obține evenimente.',
    noEvents: 'Nu există evenimente',
    thisWeek: 'Urmează săptămâna aceasta',
    thisMonth: 'Urmează luna aceasta',
    nextMonth: 'Urmează luna viitoare',
    updateEvent: 'Actualizați evenimentul',
    selectEventLanguage: 'Selectați limba evenimentului',
    selectLanguage: 'Selectați limba evenimentului',
    title: 'Titlu',
    altName: 'Nume alternativ',
    category: 'Categorie',
    description: 'Descriere',
    translatedBy: 'Tradus de',
    imageRequired: 'Vă rugăm să selectați o imagine de eveniment.',
    aboutPage:
      'Pe această pagină  găsești toate întâlnirile (deocamdată doar virtuale) pe care le organizăm pentru a-ți oferi o paletă largă de informații privind utilizarea uleiurilor esențiale și produselor doTERRA pentru ceea ce ai tu nevoie. Te așteptăm cu iubire.',
    public: 'Deschis publicului larg',
    exclusive:
      'Destinate celor din echipa Wonderland Essentials și invitaților lor neînrolaţi în doTERRA',
    private: 'Destinate doar celor din echipa Wonderland Essentials',
  },
  // single event page
  eventPage: {
    eventTitle: 'Titlul evenimentului',
    failedToFetch: 'Nu s-au putut prelua datele despre evenimente.',
    addEventToMyCallendar: 'Adaugă în calendar',
    joinEvent: 'Participă',
    eventType: 'Tipul evenimentului',
    eventRecurrence: 'Recurență',
    addZoomlink: 'Adaugă link de Zoom',
    eventDescription: 'Descriere',
    eventOccurences: 'Apariții',
    organizerAndGuests: 'Organizator și invitați',
    deleteEvent: 'Șterge evenimentul',
    editEvent: 'Editați evenimentul',
    editEventTitle: 'Actualizați evenimentul',
    eventLink: 'Link eveniment: ',
    from: 'De la',
    to: 'la',
    customMobileButton: 'Text afișat pe buton',
    customMobileButtonPlaceholder: 'Intră în Zoom',
    toastify: {
      eventUpdated: 'Evenimentul a fost actualizat.',
      eventNotUpdated: 'Evenimentul nu poate fi actualizat.',
      eventDeleted: 'Evenimentul a fost actualizat.',
      eventNotDeleted: 'Evenimentul nu se poate șterge.',
      articleNotDeleted:
        'Articolul nu a putut fi șters. Vă rugăm să încercați din nou.',
      articleDeleted: 'Articolul a fost șters cu succes',
    },
    errors: {
      weekRecurrence: 'Trebuie să alegeți cel puțin o zi.',
      host: 'Gazda evenimentului este necesară.',
      hostLength:
        'Câmpul organizatorului și al invitaților trebuie să aibă mai puțin de 65 de caractere.',
      description: 'Descrierea este necesară.',
    },
    breadcrumbs: {
      all: 'Toate Evenimentele',
    },
    deleteModal: {
      title: 'Confirmați ștergerea evenimentului',
      confirm: 'Da, șterge',
      body: {
        text: 'Sunteți pe cale să ștergeți complet acest eveniment și informațiile sale. ',
        secondText:
          'Evenimentul nu va mai fi vizibil. Această acțiune nu poate fi anulată.',
        areYouSure: 'Sunteți sigur că doriți să',
        deleteHighlight: 'ștergeți permanent',
        end: 'acest eveniment?',
      },
    },
    confirmModal: {
      title: 'Confirmați modificările',
      confirm: 'Am înțeles',
      body: {
        text: 'Actualizarea unui eveniment activ poate avea un impact asupra unor utilizatori.',
        secondText: 'Sunteți sigur că doriți să faceți asta?',
      },
    },
    customRecurrence: {
      title: 'Recurență personalizată',
      confirm: 'Terminat',
      repeat: 'Repetă în fiecare',
      repeatOn: 'Repetă la',
      includeWeekends: 'Includeți weekendurile',
      repeatInterval: 'Intervalul de repetiție',
      daily: {
        dailyUntil: 'Zilnic, până la',
        dailyAfter: 'Zilnic, ',
        dailyTimes: ' ori',
        dailyNever: 'Zilnic',
      },
      monthly: {
        monthlyUntil: 'În fiecare lună, până la ',
        monthlyAfter: 'Lunar, ',
        monthlyTimes: ' ori',
        monthlyNever: 'Lunar',
        monthlyEveryDay: 'Lunar, in ziua de ',
        monthlyEvery: 'Lunar, ',
        first: ' in prima ',
        second: ' in a doua ',
        third: ' in a treia ',
        fourth: ' in a patra ',
        monthlyEnd: ' a lunii',
        monday: ' luni ',
        tuesday: ' marti ',
        wednesday: ' miercuri ',
        thursday: ' joi ',
        friday: ' vineri ',
        saturday: ' sambata ',
        sunday: ' duminica ',
      },
      yearly: {
        yearlyUntil: 'În fiecare an, până la',
        yearlyAfter: 'Anual, ',
        yearlyTimes: ' ori',
        yearlyNever: 'Anual',
      },
      ends: 'Se termină',
      never: 'Niciodată',
      on: 'La',
      after: 'După',
      event: ' event',
    },
  },
  // protocols
  protocolsPage: {
    pageTitle: 'Protocoale',
    addProtocol: '+ Protocol nou',
    favoriteText: 'Arată doar favorite',
    showing: {
      pre: 'Arată',
      post: 'Protocol',
      plural: 'Protocoale',
      none: 'Nici un protocol',
    },
    categories: {
      all: 'Toate',
      physical: 'Fizic',
      emotional: 'Emotional',
    },
    filters: 'Filtre',
    protocolAdded: 'Protocoale adăugate cu succes.',
    protocolNotSaved: 'Protocolul nu a putut fi salvat.',
    addProtocolTitle: 'Create New Protocol',
    addProtocolButton: 'Protocol nou',
    selectProtocolLanguage: 'Selectați limba protocolului',
    selectLanguage: 'Selectați limba protocolului',
    title: 'Titlul protocolului',
    altName: 'Nume alternativ',
    category: 'Categoria protocolului',
    description: 'Descriere',
    ingredients: 'Ingrediente',
    benefits: 'Beneficii',
    cautions: 'Precauții',
    translatedBy: 'Mulțumiri',
    imageRequired: 'Vă rugăm să selectați o imagine de protocol.',
  },

  // single protocol page
  protocolPage: {
    failedToFetch: 'Nu s-au putut prelua datele protocolului.',
    breadcrumbs: {
      all: 'Toate Protocoalele',
    },
    selectLanguage: 'Selectați limba protocolului',
    faledToFetchSimilar: 'Nu s-au putut obține protocoale similare.',
    updatedProtocol: 'Protocolul a fost actualizat cu succes.',
    duplicate: 'Exista deja un protocol cu acest nume.',
    deletedProtocol: 'Protocolul a fost șters cu succes.',
    updateError: 'Protocolul nu poate fi actualizat',
    deleteError: 'Protocolul nu poate fi șters',
    updated: 'Protocolul a fost actualizat cu succes.',
    failedUpdate: 'Nu s-au putut actualiza detaliile protocolului.',
    editProtocol: 'Editați protocolul',
    addProtocolTitle: 'Creați un protocol nou',
    updateProtocol: 'Actualizați protocolul',
    deleteProtocol: 'Ștergeți protocolul',
    title: 'Titlul protocolului',
    altName: 'Nume alternativ',
    physical: 'Fizic',
    emotional: 'Emoţional',
    altSearchTerms: 'Termeni alternativi de căutare',
    placeholderSearchTerms: 'Adăugați termeni',
    topicalRecommendation: 'Recomandare Topică',
    aromaticalRecommendation: 'Recomandare Aromatică',
    internalRecommendation: 'Recomandare Internă',
    additionalRecommendations: 'RECOMANDĂRI SUPLIMENTARE',
    protocolFieldRequired: 'Este necesară descrierea.',
    protocolDisclamer:
      '*Aceste informații reprezintă o compilație de sugestii făcute de cei care au folosit uleiuri esențiale și nu intentioneaza a substitui sfatul unui medic profesionist. Este un document cu caracter informativ sintetizat din experiențe personale și trebuie tratate ca atare. Produsele și metodele recomandate nu sunt destinate să diagnosticheze, să trateze, să vindece sau să prevină vreo boală și nici nu intenționează să înlocuiască ajutorul medical adecvat.',
    physicalTopicalRecommendation: 'Recomandare Fizică Topică',
    physicalAromaticalRecommendation: 'Recomandare Fizică Aromatică',
    physicalInternalRecommendation: 'Recomandare Fizică Internă',
    physicalAdditionalRecommendation: 'Recomandări Fizice Suplimentare',
    addPhysicalProtocol: 'Adăugați Protocol Fizic',
    emotionalTopicalRecommendation: 'Recomandare Emoțională Topică',
    emotionalAromaticalRecommendation: 'Recomandare Emoțională Aromatică',
    emotionalInternalRecommendation: 'Recomandare Emoțională Internă',
    emotionalAdditionalRecommendations: 'Recomandări Emoționale Suplimentare',
    addEmotionalProtocol: 'Adăugați protocol emoțional',
    noProtocolType: 'Trebuie să alegeți cel puțin un tip de protocol.',
    deleteModal: {
      title: 'Confirmă ștergerea',
      confirm: 'Da, șterge',
      body: {
        top: 'Sunteți pe cale să ștergeți complet protocolul și informațiile sale. Această acțiune nu poate fi anulată.',
        areYouSure: 'Sunteți sigur că doriți să',
        deleteHighlight: 'ștergeți permanent',
        end: 'acest protocol?',
      },
    },
    zonesDialogButton: 'Zone de aplicare',
    zonesModal: {
      title: 'Zone de aplicare',
      confirm: 'Salveaza',
      solesOfFeet: 'Tălpi',
      neck: 'Gât',
      forhead: 'Frunte',
      armpits: 'Sub brațe',
      solarPlexus: 'Plex solar',
      heart: 'Inimă',
      sacrum: 'Osul sacru',
      lombarRegion: 'Zona lombară',
      sinuses: 'Sinusuri',
      underTheNose: 'Sub nas',
      chest: 'Piept',
      backOfNeck: 'Ceafă',
      spine: 'Coloană vertebrală',
      diaphragm: 'Diafragmă',
      back: 'Spate',
      topOfHead: 'Vârful capului',
      aroundBellyButton: 'În jurul buricului',
      lowerAbdomen: 'Abdomen inferior',
      insideWristCreses: 'Încheieturile mâinilor pe interior',
      temples: 'Tâmple',
      baseOfTheSkull: 'La baza craniului',
      behindTheEars: 'În spatele urechilor / Mastoid',
      alongTheArms: 'De-a lungul brațelor',
      abdomen: 'Abdomen (Burtă/ Burtică)',
      shoulders: 'Umeri',
      kidneys: 'Zona rinichilor',
      midlineOfTheBelly: 'Pe abdomen de sus in jos',
      insideElbowCreases: 'Îndoitură interioară a cotului',
      outerLineOfTheJaw: 'Linia exterioară a maxilarului (mandibulă)',
      sternBone: 'Stern',
      outsideWristCreases: 'Exteriorul incheieturilor mainilor',
      innerThighArea: 'Zona internă a coapselor',
      theBaseOfTheNeck: 'Baza gâtului',
      tailBone: 'Coccis',
      feet: 'Laba picioarelor',
      behindTheKnees: 'În spatele genunchilor',
      liverArea: 'Zona ficatului',
    },
    confirmModal: {
      title: 'Confirmați modificările',
      confirm: 'Am înțeles',
      body: {
        text: 'Actualizarea unui protocol activ poate avea un impact asupra unor utilizatori. Ești sigur că vrei să faci asta?',
      },
    },
  },
  // 404 not found page
  notFoundPage: {
    notFound: 'Pagina nu a fost găsită',
    title: 'Hopa! Se pare că s-a spart ceva.',
    content: 'Sperăm că nu este o sticlă de ulei esențial...',
    button: 'Înapoi acasă',
  },
  // contact page
  contactPage: {
    title: 'Hai să ne auzim',
    text: 'Completează formularul. Este super ușor.',
    content: 'Ne puteți contacta și prin email la ',
    website: 'support@wonderlandessentials.com',
    contactEmail: 'mailto:support@wonderlandessentials.com',
    button: 'Trimite Mesaj',
    typeEmail: 'Adresa de email',
    textAreaLabel: 'Spune-ne pe scurt cum te putem ajuta',
    messageSent: 'Mesajul tău a fost trimis.',
  },
  // request error messages
  errors: {
    generic: {
      serverError: 'Server error.',
      sessionExpired: 'Sesiunea a expirat. Conectați-vă din nou.',
      notAllowed: 'Nu aveți acces pentru a vizualiza această pagină',
    },
    auth: {
      login: {
        notFound: 'Utilizatorul nu există ',
        wrongPass: 'Parola incorectă.',
        generic: 'A apărut o eroare. Incearcă din nou mai tarziu.',
      },
    },
    products: {
      badRequest: 'Bad request.',
      notFound: 'Produsul nu a fost găsit.',
    },
    protocols: {
      badRequest: 'Bad request.',
      notFound: 'Protocolul nu a fost găsit.',
    },
    events: {
      badRequest: 'Bad request.',
      notFound: 'Evenimentul nu a fost găsit.',
    },
    users: {
      badRequest: 'Bad request.',
      notFound: 'Utilizatorul nu a fost găsit.',
    },
  },
  dashboard: {
    title: 'Dashboard',
    statistics: 'Statistici',
    blendOfTheMonth: 'Amestecul lunii',
    products: 'Produse doTERRA',
    protocols: 'Protocoale',
    languages: 'Limbi',
    readMore: 'Mai mult',
    blendAdd: '+ Amestec nou',
    blendEdit: 'Editează',
    allBlends: 'Amestecuri',
    monthlyOffer: 'Ofertele lunii',
    noBlends: 'Nici un amestec',
    blendDisclamer:
      '* Aceste informații reprezintă o compilație de sugestii făcute de cei care au folosit uleiuri esențiale și nu intentioneaza a substitui sfatul unui medic profesionist. Este un document cu caracter informativ sintetizat din experiențe personale și trebuie tratate ca atare. Produsele și metodele recomandate nu sunt destinate să diagnosticheze, să trateze, să vindece sau să prevină vreo boală și nici nu intenționează să înlocuiască ajutorul medical adecvat.',
    applicationZones: 'Zone de aplicare',
    frequency: 'Frecvență',
    duration: 'Durată',
    modal: {
      blendTitle: 'Nume amestec',
      blendDescription: 'Descriere amestec',
      new: 'Ofertă lunară nouă',
      editOffer: 'Modifică oferta lunară',
      addBlend: 'Adaugă amestec nou',
      editBlend: 'Alege amestecul lunii',
      deleteBlend: 'Sterge amestec',
      selectLanguage: 'Limba',
      selectBlend: 'Alege amestecul',
      title: 'Titlu',
      url: 'URL',
      ok: 'Salvează',
    },
    deleteModal: {
      title: 'Confirma stergerea amestecului',
      confirm: 'Da, sterge',
      body: {
        top: 'Urmeaza sa stergi acest amestec si toate datele. Aceasta actiune este ireversibila.',
        areYouSure: 'Esti sigur ca doresti sa ',
        deleteHighlight: 'stergi permanent',
        end: 'acest amestec?',
      },
    },
  },
  deleteModal: {
    title: 'Confirmă ștergerea',
    confirm: 'Da, șterge',
    body: {
      top: 'Sunteți pe cale să ștergeți complet amestecul și informațiile sale. Această acțiune nu poate fi anulată.',
      areYouSure: 'Sunteți sigur că doriți să',
      deleteHighlight: 'ștergeți permanent',
      end: 'acest amestec?',
    },
  },
  editor: {
    generic: {
      add: 'Adaugă',
      cancel: 'Anulează',
    },
    link: {
      linkTitle: 'Titlu',
      linkTarget: 'Link',
      linkTargetOption: 'Deschide fereastră separată',
      link: 'Link nou',
      unlink: 'Elimină link',
    },
  },
  // eduCare page
  eduCarePage: {
    pageTitle: 'eduCare',
    addNewArticle: '+ Adaugă',
    featured: 'Recomandate',
    other: 'Altele',
    search: 'Caută în eduCare',
    addEditPage: {
      title: 'Articol nou',
      editTitle: 'Modifică articol',
      other: 'Alte articole',
      editArticle: 'Modifică articolul',
      success: 'Articolul a fost salvat.',
      fail: 'A apărut o eroare. Incearcă din nou mai tarziu.',
    },
    toasts: {
      success: 'Articolul a fost salvat.',
      fail: 'Nu s-a putut salva articolul. Încercați din nou.',
    },
    buttons: {
      save: 'Salvează',
      cancel: 'Anulează',
    },

    title: 'Titlu articol',
    author: 'Selecteaza Autorul',
    checkboxArticle: 'Articol recomandat',
    deleteArticle: {
      title: 'Confirmați ștergerea articolului',
      confirm: 'Sunteți sigur că doriți să ștergeți permanent acest articol?',
      buttonDelete: 'Șterge articolul',
    },
    toastify: {
      articleNotDeleted:
        'Articolul nu a putut fi șters. Vă rugăm să încercați din nou.',
      articleDeleted: 'Articolul a fost șters cu succes.',
    },
  },
};
