import { useState, useEffect } from 'react';

const getState = () => {
  if (document.visibilityState === 'hidden') {
    return 'hidden';
  }
  if (document.hasFocus()) {
    return 'active';
  }
  return 'passive';
};

export const usePageVisibility = () => {
  const [pageState, setPageState] = useState(getState());

  useEffect(() => {
    const visibilityChange = () => {
      setPageState(getState());
    };
    //add listeners
    ['focus', 'blur', 'visibilitychange', 'resume'].forEach((type) => {
      window.addEventListener(type, visibilityChange, {
        capture: true,
      });
    });
    //cleanup
    return () => {
      ['focus', 'blur', 'visibilitychange', 'resume'].forEach((type) => {
        window.removeEventListener(type, visibilityChange);
      });
    };
  });
  return pageState;
};
